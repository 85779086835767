import React, { useContext, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { useQuery, gql, useMutation } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { UserContext } from "../../context/userContext"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon" // Import Luxon DateTime

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { Dialog } from "primereact/dialog"
import { Toast } from "primereact/toast"

//Ga

import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

const GET_NOTES = gql`
  query coachingNotesByStaff($staff_id: ID!) {
    coachingNotesByStaff(staff_id: $staff_id) {
      date_created
      note_coaching_id
      organization_cohort_name
      coach_notes
      note_date
      participant_id
      participant_name_full
      coach_note_lesson_goal
      coach_note_well_being_barriers
      coach_note_accountability_message
      coach_note_engagement_level
    }
  }
`
const DELETE_NOTES = gql`
  mutation deleteCoachingNote($note_coaching_id: ID!) {
    deleteCoachingNote(note_coaching_id: $note_coaching_id) {
      message
    }
  }
`

export default function OrganizationList() {
  const userCtx = useContext(UserContext)
  const staffID = userCtx.session_id
  const date = getActualTime()
  const navigate = useNavigate()
  const toast = useRef(null)
  //Ga
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }

  const { loading, error, data } = useQuery(GET_NOTES, {
    variables: { staff_id: staffID },
    fetchPolicy: "network-only",
  })
  const [
    deleteCoachingNote,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_NOTES)
  useEffect(() => {
    if (toast.current) {
      if (mutationData?.deleteCoachingNote) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: mutationData.deleteCoachingNote.message,
          life: 3000,
        })
      } else if (mutationError) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: mutationError.message,
          life: 3000,
        })
      }
    }
  }, [mutationData, mutationError])

  const [visible, setVisible] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>
  console.log(data.coachingNotesByStaff)
  // Actions Edit, Delete and Copy  Link
  const action = ({ note_coaching_id }) => {
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            content_id: note_coaching_id,
            content_type: "Notes",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          deleteCoachingNote({
            variables: { note_coaching_id },
            refetchQueries: [
              {
                query: GET_NOTES,
                variables: { staff_id: staffID },
              },
            ],
          })
        },
      },
    ]
    return (
      <SplitButton
        label="Edit"
        model={items}
        onClick={() => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            content_id: note_coaching_id,
            content_type: "Notes",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/form/edit/note/1/${note_coaching_id}`)
        }}
      />
    )
  }

  const nameTemplate = (rowData) => {
    return (
      <Link
        style={{
          textDecoration: "underline",
          color: "var(--branded-light-green)",
          cursor: "pointer",
        }}
        onClick={() => {
          gaTrackingParams.event = "button_click"

          gaTrackingParams.payload = {
            button_name: rowData.participant_name_full,
            button_text: rowData.participant_name_full,
            buuton_type: "participant_link",
            button_location: "StaffUserHome",
          }

          gATracking(gaTrackingParams)
        }}
        to={`/participant/profile/${rowData.participant_id}`}
      >
        {rowData.participant_name_full}
      </Link>
    )
  }

  const notesTemplate = (rowData) => {
    return (
      <span
        style={{
          textDecoration: "underline",
          color: "var(--branded-light-green)",
          cursor: "pointer",
        }}
        onClick={() => {
          gaTrackingParams.event = "button_click"

          gaTrackingParams.payload = {
            button_name: "view_notes_btn",
            button_text: "view notes",
            buuton_type: "link",
            button_location: "/staff-user/notes",
          }

          gATracking(gaTrackingParams)

          setSelectedNote(rowData)
          setVisible(true)
        }}
      >
        View Notes
      </span>
    )
  }
  return (
    <div className="p-col-12 p-md-3">
      <div className="flex gap-5 align-items-center mb-4">
        <h2>Coaching Notes</h2>
        <Toast ref={toast} />

        <Button
          icon="pi pi-plus"
          rounded
          onClick={() => navigate("/form/note/1")}
          severity="success"
          style={{
            backgroundColor: "var(--branded-dark-blue)",
            borderColor: "var(--branded-dark-blue)",
          }}
          aria-label="Add"
        />
      </div>

      <DataTable
        value={data.coachingNotesByStaff}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="participant_name_full"
          header="Participant "
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          body={nameTemplate}
        />
        <Column
          sortable
          field="organization_cohort_name"
          header="Organization Cohort"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="note_date"
          header="Date"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          body={(rowData) =>
            DateTime.fromISO(rowData.note_date).toFormat("MM/dd/yyyy")
          }
        />

        <Column
          field="coach_notes"
          header="Note"
          body={notesTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />

        <Column
          body={(row) => action(row)}
          header="Action"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>

      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        header="Coaching Note Details"
        modal
        style={{ width: "50vw" }}
      >
        {selectedNote && (
          <div>
            <h2>Engagement Level</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedNote.coach_note_engagement_level,
              }}
            />

            <h2>Lesson Goal</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedNote.coach_note_lesson_goal,
              }}
            />
            <h2>Notes</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedNote.coach_notes,
              }}
            />
            <h2>Well Being Barriers</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedNote.coach_note_well_being_barriers,
              }}
            />
            <h2>Accountability Message</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedNote.coach_note_accountability_message,
              }}
            />
          </div>
        )}
      </Dialog>
    </div>
  )
}
