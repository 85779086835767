import React, { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { gql, useQuery, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"

import * as Yup from "yup"

import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { Button } from "primereact/button"
import { InputTextarea } from "primereact/inputtextarea"

import { LoaderMedium } from "../../components/Loaders"
import HandleTypeFile from "./components/HandleTypeFile"

import roundToNearestHalfOrWhole from "../../helpers/roundToNearestHalfOrWhole"

const GET_RESOURCE = gql`
  query GetResource($id: ID!) {
    resource(id: $id) {
      id
      name
      avgTime
      type
      content {
        description
        htmlTag
        gs
        src
        thumbnail
        title
        value
      }
    }
  }
`

const EDIT_RESOURCE = gql`
  mutation EditResource($resource: ResourceInput!) {
    editResource(resource: $resource) {
      id
    }
  }
`

function ErrorMessage({ touched, error }) {
  return touched && error ? <small className="p-error">{error}</small> : null
}

const EditResource = () => {
  const [resource, setResource] = useState(null)
  const { idResource } = useParams()
  const navigate = useNavigate()

  const { loading, error } = useQuery(GET_RESOURCE, {
    fetchPolicy: "network-only",
    variables: { id: idResource },
    onCompleted: (data) => {
      setResource(data.resource)
    },
  })

  const [editResource] = useMutation(EDIT_RESOURCE, {
    onCompleted: () => {
      navigate("/ths-admin/resources")
    },
  })

  if (loading || !resource) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const initialValues = {
    name: resource?.name || "",
    avgTime: resource?.avgTime || "",
    description: resource?.content?.description || "",
    title: resource?.content?.title || "",
    gs: resource?.content?.gs || "",
    src: resource?.content?.src || "",
  }

  const renderTypeFile = () => {
    const typeFiles = ["image", "video", "audio"]
    const type = resource?.type
    if (typeFiles.includes(type)) {
      return <HandleTypeFile type={type} />
    }
  }

  const handleValidationSchema = () => {
    const typeFiles = ["image", "video", "audio"]
    const type = resource?.type
    if (typeFiles.includes(type)) {
      return Yup.object().shape({
        name: Yup.string().required("Name is required"),
        avgTime: Yup.number()
          .required("Average time is required")
          .positive("Average time must be a positive number")
          .integer("Average time must be an integer"),
        description: Yup.string().required("Description is required"),
        title: Yup.string().required("Title is required"),
        gs: Yup.string().required("Resource is required"),
      })
    } else {
      return Yup.object().shape({
        name: Yup.string().required("Name is required"),
        avgTime: Yup.number()
          .required("Average time is required")
          .positive("Average time must be a positive number")
          .integer("Average time must be an integer"),
        description: Yup.string().required("Description is required"),
        title: Yup.string().required("Title is required"),
      })
    }
  }

  const validationSchema = handleValidationSchema()

  return (
    <div>
      <h2>Edit Resource</h2>
      <p>
        Back to <Link to="/ths-admin/resources">Resources List</Link>
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const editedResource = {
            id: idResource,
            name: values.name,
            avgTime: parseInt(values.avgTime),
            content: {
              description: values.description,
              title: values.title,
              gs: values.gs,
            },
          }
          editResource({ variables: { resource: editedResource } })
          setSubmitting(false)
        }}
      >
        {(formik) => (
          <Form>
            <div className="p-fluid p-field mb-3">
              <label htmlFor="name">Name</label>
              <InputText
                name="name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className={
                  formik.touched.name && formik.errors.name ? "p-invalid" : null
                }
              />
              <ErrorMessage
                touched={formik.touched.name}
                error={formik.errors.name}
              />
            </div>

            <div className="p-fluid p-field mb-3">
              <label htmlFor="avgTime">Average Time (minutes)</label>
              <InputNumber
                inputId="minmax-buttons"
                value={
                  formik.values.avgTime
                    ? roundToNearestHalfOrWhole(formik.values.avgTime / 60000)
                    : 0
                }
                onValueChange={(e) => {
                  const ms = e.value * 60000
                  formik.setFieldValue("avgTime", ms)
                }}
                min={0}
                step={0.5}
                showButtons
                className={
                  formik.touched.avgTime && formik.errors.avgTime
                    ? "p-invalid"
                    : null
                }
              />
              <ErrorMessage
                touched={formik.touched.avgTime}
                error={formik.errors.avgTime}
              />
            </div>

            <div className="p-fluid p-field mb-3">
              <label htmlFor="description">Description</label>
              <InputTextarea
                name="description"
                id="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                className={
                  formik.touched.description && formik.errors.description
                    ? "p-invalid"
                    : null
                }
              />
              <ErrorMessage
                touched={formik.touched.description}
                error={formik.errors.description}
              />
            </div>

            <div className="p-fluid p-field mb-3">
              <label htmlFor="title">Title</label>
              <InputText
                name="title"
                id="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                className={
                  formik.touched.title && formik.errors.title
                    ? "p-invalid"
                    : null
                }
              />
              <ErrorMessage
                touched={formik.touched.title}
                error={formik.errors.title}
              />
            </div>

            {renderTypeFile()}

            <Button
              type="submit"
              label="Submit"
              className="p-mt-2"
              disabled={!formik.isValid || !formik.dirty}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditResource
