import React, { useContext } from "react"
import _ from "lodash"

import { FormContext } from "../context/FormContext"
import { useParams } from "react-router-dom"

// Apollo
import { useQuery, gql } from "@apollo/client"

// PrimeReact
import { Button } from "primereact/button"

const GET_FORM = gql`
  query GetFormBlocksNav($idTemplate: ID!) {
    template(idTemplate: $idTemplate) {
      blocks {
        block_title
        idBlock
        index
      }
    }
  }
`

export default function FormNav() {
  const form = useContext(FormContext)

  const { formPage, idTemplate } = useParams()

  const { loading, data } = useQuery(GET_FORM, {
    fetchPolicy: "network-only",
    variables: { idTemplate },
  })

  if (loading) return null

  const sortedBlocks = _.sortBy(data.template.blocks, ["index"])

  const list = _.map(sortedBlocks, (block) => {
    const handleClass =
      block.index === Number(formPage)
        ? "flex p-3 gap-2 font-semibold"
        : "flex p-3 gap-2"

    const handleChecked =
      form.blocksSaved[block.idBlock] && "bg-green-100 text-green-800"

    const isBlockSaved = form.blocksSaved[block.idBlock]

    const handleIco = isBlockSaved
      ? "pi pi-check bg-green-100"
      : "pi pi-minus-circle"

    return (
      <li key={block.idBlock} className={`${handleClass} ${handleChecked}`}>
        <span className={handleIco} />
        {block.block_title}
      </li>
    )
  })

  return (
    <div>
      <ul className="flex flex-column m-0 mb-3 p-0 border-1 border-200 border-round">
        {list}
      </ul>
    </div>
  )
}
