import React, { useState, useEffect, useContext } from "react"
import { Card } from "primereact/card"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { UserContext } from "../../../context/userContext"

export default function ParticipantHome() {
  // Participant Data
  const userCtx = useContext(UserContext)

  const [isAdmin, setIsAdmin] = useState(false)

  const [participant, setParticipant] = useState({
    name: "John Due",
    email: "John@mail.com",
    smsPhone: "11-111-1111",
    phone: "00-000-0000",
    birthdate: "1980-05-19",
    timezone: "EST",
    workTitle: "Manager",
    tenure: "+10 Years",
    primaryFocus: "Stress Control",
    secondFocus: "Leadership",
    changehabbits: "yes",
    recievesupport: "yes",
    showdemographicData: "yes",
    gender: "male",
    educationLevel: "bachelor",
    relacionshipStatus: "Married",
    race: "white",
    ethnicity: "CostaRican",
    organizationName: "Siftia",
    currentCohort: "Resilience to Stress",
    coursename: "Stress I",
    lastmodDate: " 2024-05-22",
    staffFullName: "Marcelo Rojas",
    termsandPolicy: "yes",
    status: "active",
    datashared: "yes",
    datastore: "no",
    coursesCompleted: "10",
    profileImg: "https://primefaces.org/cdn/primereact/images/usercard.png",
  })
  const [isEthoSelected, setIsEthoSelected] = useState(false)

  useEffect(() => {
    setIsEthoSelected(participant.showdemographicData === "yes")
    setIsAdmin(
      userCtx.role_id === "sys-admin" ||
        userCtx.role_id === "ths-admin" ||
        userCtx.role_id === "staff"
    )
  }, [participant.showdemographicData, userCtx.role_id])
  // Profile View
  return (
    // Container
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          columnGap: "0px",
          rowGap: "0px",
        }}
      >
        <div
          className="flex flex-column gap-5 p-5"
          style={{ gridArea: "1 / 1 / 2 / 2" }}
        >
          <Card className="w-30rem justify-content-center align-content-center shadow-5 border-round-2xl">
            <h2>{participant.name}</h2>
            <hr />
            <h3>Personal Data:</h3>

            <div className="flex gap-3 align-items-center">
              <h4>Email:</h4>
              <p>{participant.email}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Birth Date:</h4>
              <p>{participant.birthdate}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Timezone:</h4>
              <p>{participant.timezone}</p>
            </div>
            <div className="flex gap-3">
              <div className="flex gap-2 align-items-center ">
                <h4>SMS Phone:</h4>
                <p>{participant.smsPhone}</p>
              </div>

              <div className="flex gap-2 align-items-center">
                <h4>Phone Number:</h4>
                <p>{participant.phone}</p>
              </div>
            </div>
          </Card>
          <div></div>
        </div>
        <div
          className="flex flex-column gap-5 p-5"
          style={{ gridArea: "1 / 2 / 2 / 3" }}
        >
          <Card
            className="h-29rem justify-content-center align-content-center shadow-5 border-round-2xl "
            style={{ width: "42rem" }}
          >
            <h2>Motivation</h2>
            <hr />
            <div className="flex gap-3 align-items-center mt-6">
              <h4>Primary Focus:</h4>
              <p>{participant.primaryFocus}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Secondary Focus:</h4>
              <p>{participant.secondFocus}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Are you Ready to Change Habbits?:</h4>
              <p>{participant.changehabbits}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Are you Ready to Recive Support?:</h4>
              <p>{participant.recievesupport}</p>
            </div>
          </Card>
        </div>
        <div style={{ gridArea: "2 / 1 / 3 / 3" }}>
          <Card
            className="ml-5 justify-content-center align-content-center shadow-5 border-round-2xl "
            style={{ width: "76rem" }}
          >
            <h2>Work Data</h2>
            <hr />
            <div className="flex gap-8">
              <div>
                <div className="flex gap-3 align-items-center">
                  <h4>Organization Name:</h4>
                  <p>{participant.organizationName}</p>
                </div>
                <div className="flex gap-3 align-items-center">
                  <h4>Cohort Name:</h4>
                  <p>{participant.currentCohort}</p>
                </div>
                <div className="flex gap-3 align-items-center">
                  <h4>Organization Name:</h4>
                  <p>{participant.organizationName}</p>
                </div>
                <div className="flex gap-3 align-items-center">
                  <h4>Staff Name:</h4>
                  <p>{participant.staffFullName}</p>
                </div>
              </div>
              <div>
                <div className="flex gap-3 align-items-center">
                  <h4>Work Title:</h4>
                  <p>{participant.workTitle}</p>
                </div>
                <div className="flex gap-3 align-items-center">
                  <h4>Tenure:</h4>
                  <p>{participant.tenure}</p>
                </div>
                <div className="flex gap-3 align-items-center">
                  <h4>Last Modification:</h4>
                  <p>{participant.lastmodDate}</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <hr className="mb-5"></hr>
      <div className="flex">
        {isEthoSelected ? (
          <Card className="w-30rem ml-5 justify-content-center align-content-center shadow-5 border-round-2xl ">
            <h2> Demographic Information</h2>
            <hr></hr>
            <div className="flex gap-3 align-items-center">
              <h4>Gender:</h4>
              <p>{participant.gender}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Education Level:</h4>
              <p>{participant.educationLevel}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Relacionship Status:</h4>
              <p>{participant.relacionshipStatus}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>Race:</h4>
              <p>{participant.race}</p>
            </div>
            <div className="flex gap-3 align-items-center">
              <h4>ethnicity:</h4>
              <p>{participant.ethnicity}</p>
            </div>
          </Card>
        ) : (
          <Card
            className=" w-30rem ml-5 justify-content-center align-content-center shadow-5 border-round-2xl "
            style={{ display: "none" }}
          ></Card>
        )}
        {isAdmin ? (
          <Card className="w-30rem ml-5 mt-5 justify-content-center align-content-center shadow-5 border-round-2xl ">
            <h2> Admin Information </h2>
          </Card>
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}
