import React, { useEffect, useContext } from "react"
import { useFormikContext } from "formik"

// Ctx
import { UserContext } from "../../../../context/userContext"

export default function ParticipantCoachingRegistrationId({ field }) {
  const formik = useFormikContext()
  const user = useContext(UserContext)

  useEffect(() => {
    // formik.setFieldValue(field.field_name, user.staff_id)
    // const trigger = field.field_name
    // if (trigger === "staff_id") {
    //   return
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ display: "none" }}>
      <input
        type="hidden"
        name={field.field_name}
        value={formik.values[field.field_name]}
      />
    </div>
  )
}
