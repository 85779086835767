import React, { useState, useMemo } from "react"
import _ from "lodash"

// Form Ctx
import { FormContext } from "./context/FormContext"

// Apollo
import { useQuery, gql } from "@apollo/client"

// Components
import FormContainer from "./FormContainer"
import { LoaderMedium } from "../../components/Loaders"
import { Divider } from "primereact/divider"

// Nav
import FormNav from "./nav/FormNav"

const GET_REGISTRATION_FORM = gql`
  query GetRegistrationForm {
    template(idTemplate: "profile") {
      template_name
      template_title
      template_description
    }
  }
`

export default function Registration() {
  const [blocks, setBlocks] = useState([])
  const [blocksRequired, setBlocksRequired] = useState([])
  const [blocksSaved, setBlocksSaved] = useState({})

  const formContextValue = useMemo(() => ({}), [])

  const { loading, data } = useQuery(GET_REGISTRATION_FORM, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log(data)
      const sortedBlocks = _.sortBy(data.template.blocks, ["index"])
      const initBlocksSaved = _.reduce(
        data.template.blocks,
        (acc, block) => {
          return {
            ...acc,
            [block.block_name]: false,
          }
        },
        {}
      )
      // Check-----
      const initBlocksRequired = _.reduce(
        data.template.blocks,
        (acc, block) => {
          if (!block.system_required) return acc
          return [...acc, block.block_name]
        },
        []
      )
      setBlocksSaved(initBlocksSaved)
      setBlocksRequired(initBlocksRequired)
      setBlocks(sortedBlocks)
    },
  })

  if (loading) return <LoaderMedium />

  return (
    <FormContext.Provider value={formContextValue}>
      <div>
        <h2>{data.template.template_title}</h2>
        <p>{data.template.template_description}</p>
        <Divider />
        <div className="flex overflow-hidden mt-4 gap-5">
          <div className="flex-none flex">
            <FormNav />
          </div>
          <div className="flex-grow-1 flex">
            <FormContainer />
          </div>
        </div>
      </div>
    </FormContext.Provider>
  )
}
