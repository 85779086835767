import React, { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { storage as firebaseStorage } from "../../../firebase"

import { AddLessonCtx } from "../context/addLessonCtx"

// PR
import { FileUpload } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"

export default function StorageFileUpload(props) {
  const context = useContext(AddLessonCtx)

  const [timestampPrefix] = useState(Date.now())
  const [progress, setProgress] = useState(0)
  const [fileUrl, setFileUrl] = useState(context.newResource.content.gs || null)

  const { getStorage, ref, uploadBytesResumable, deleteObject } =
    firebaseStorage

  const storage = getStorage()
  const FOLDER_STRUCT = `/resources/${props.type}`

  useEffect(() => {
    if (context.newResource.content.gs) {
      setFileUrl(context.newResource.content.gs)
      setProgress(100) // Assuming 100% progress if a file is already loaded
    }
  }, [context.newResource.content.gs])

  const handleContentAccept = () => {
    switch (props.type) {
      case "audio":
        return "audio/*"
      case "video":
        return "video/*"
      case "file":
        return "application/pdf"
      default:
        return ""
    }
  }

  const removeHandler = async () => {
    if (!fileUrl) return

    const currentFilename = fileUrl.split("/").pop()
    const fullFilePath = `${FOLDER_STRUCT}/${currentFilename}`
    const fileRef = ref(storage, fullFilePath)

    try {
      await deleteObject(fileRef)
      setProgress(0)
      setFileUrl(null)
      context.handleOnChange("gs", null)
    } catch (error) {
      console.log(error) // please handle ERR!!
    }
  }

  const customUploadHandler = (e) => {
    const [file] = e.files
    const uniqueFilename = `${timestampPrefix}_${file.name}`
    const path = `${FOLDER_STRUCT}/${uniqueFilename}`
    const storageRef = ref(storage, path)

    const metadata = {
      contentType: file?.type || handleContentAccept(),
    }
    const uploadTask = uploadBytesResumable(storageRef, file, metadata)
    uploadTask.on(
      "state_changed",
      (snapshot) =>
        setProgress(
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        ),
      (error) => {
        switch (error.code) {
          case "storage/object-not-found":
            console.log("storage/object-not-found", error)
            // File doesn't exist
            break
          case "storage/unauthorized":
            console.log("storage/unauthorized", error)
            // User doesn't have permission to access the object
            break
          case "storage/canceled":
            console.log("storage/canceled", error)
            // User canceled the upload
            break
          default:
            console.log(error)
        }
      },
      () => {
        const { fullPath } = uploadTask.snapshot.metadata
        setFileUrl(fullPath)
        context.handleOnChange("gs", fullPath)
      }
    )
  }

  return (
    <div>
      {fileUrl ? (
        <div className="mb-5 p-3 bg-green-50">
          <div className="mb-2">
            File Uploaded: <strong>{fileUrl.split("/").pop()}</strong>
          </div>
          <Link
            to="#"
            onClick={removeHandler}
            className="text-red-800"
            style={{ cursor: "pointer" }}
          >
            Remove Resource from Bucket
          </Link>
        </div>
      ) : (
        <div>
          <FileUpload
            customUpload
            uploadHandler={customUploadHandler}
            onRemove={removeHandler}
            onClear={() => {
              setFileUrl(null)
              setProgress(0)
            }}
            accept={handleContentAccept()}
            maxFileSize={1e9} // 1Gb
          />
          <ProgressBar
            value={Math.round(progress)}
            style={{ margin: "10px 0" }}
          />
        </div>
      )}
    </div>
  )
}
