import React, { useState, useContext } from "react"
import axios from "axios"
import { useQuery, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { DateTime } from "luxon"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

import { UserContext } from "../../context/userContext"

// services
import * as services from "../../config/servicesURL"
import gATracking from "../../helpers/gaTracking"

const GET_lESSONS_lOGS = gql`
  query participantLessonLogs {
    participantLessonLogs {
      course_lesson_name
      course_lesson_reflection
      course_lesson_complete
      date_created
    }
  }
`

export default function LessonEngagements() {
  const userCtx = useContext(UserContext)
  const [visible, setVisible] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(null)

  const { loading, error, data } = useQuery(GET_lESSONS_lOGS, {
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const lessonLog = data.participantLessonLogs || []

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const dateTemplate = (rowData) => {
    const date = DateTime.fromFormat(
      rowData.date_created,
      "yyyy-MM-dd HH:mm:ss"
    )
    const dateWithoutTime = date.startOf("day")
    console.log(date)
    return dateWithoutTime.toFormat("MM/dd/yyyy")
  }

  const lessonTemplate = (rowData) => {
    return (
      <span
        style={{
          textDecoration: "underline",
          color: "var(--branded-light-green)",
          cursor: "pointer",
        }}
        onClick={() => {
          gaTrackingParams.payload = {
            button_name: "click_link_view_reflection",
            button_text: "View Reflection",
            button_type: "click_link",
            button_location: "/ths-admin/lesson-engagements",
          }
          gATracking(gaTrackingParams)

          setSelectedLesson(rowData)
          setVisible(true)
        }}
      >
        View Reflection
      </span>
    )
  }

  const handleDownload = async () => {
    try {
      const USERS_URL = services.userURL()
      const DOWNLOAD_LESSON_XLSX = `${USERS_URL}/reports/lesson-engagement/xlsx`
      const response = await axios.get(DOWNLOAD_LESSON_XLSX, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${userCtx.token}`,
        },
      })
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `lesson-engagements.xlsx`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className="flex align-items-center justify-content-between my-5">
        <h2>Lesson Engagements</h2>
        <div className="flex">
          <Button
            className=""
            icon="pi pi-download"
            label="Download"
            onClick={handleDownload}
            style={{ backgroundColor: "var(--branded-dark-blue)" }}
          />
        </div>
      </div>
      <DataTable
        paginator
        rows={10}
        value={lessonLog}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="course_lesson_name"
          header="Course Name"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="course_lesson_complete"
          header="Lesson Complete"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          field="date_created"
          header="Date Created"
          body={dateTemplate}
        />

        <Column
          field="Lesson Reflection"
          header="Reflection"
          body={lessonTemplate}
        />
      </DataTable>

      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        header="Lesson Reflection"
        modal
        style={{ width: "50vw" }}
      >
        {selectedLesson && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedLesson.course_lesson_reflection,
              }}
            />
          </div>
        )}
      </Dialog>
    </div>
  )
}
