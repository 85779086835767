import React, { useContext, useState, useRef } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useQuery, useMutation, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { DateTime } from "luxon"
import { Toast } from "primereact/toast"

// services
import * as services from "../../config/servicesURL"
import { UserContext } from "../../context/userContext"
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

export default function Assessments() {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)
  const toast = useRef(null)
  const date = getActualTime()

  const GET_ASSESSMENTS = gql`
    query assessments {
      assessments {
        assessment_id
        assessment_name
        organization_id
        organization_cohort_name
        organization_cohort_id
        participant_id
        participant_name_full
        total_score_personal_performance
        total_score_satisfaction_life
        total_score_resilience_stress
        total_score_assessment
        date_created
      }
    }
  `
  const DELETE_ASSESSMENTS = gql`
    mutation deleteAssessment($assessment_id: ID!) {
      deleteAssessment(assessment_id: $assessment_id) {
        message
      }
    }
  `

  const [selectedCohort, setSelectedCohort] = useState(null)

  const { loading, error, data } = useQuery(GET_ASSESSMENTS, {
    fetchPolicy: "network-only",
  })

  const [
    deleteAssessment,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_ASSESSMENTS, {
    onCompleted: (data) => {
      if (data?.deleteAssessment?.message) {
        console.log("Delete mutation completed", data)
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: data.deleteAssessment.message,
            life: 3000,
          })
        }
      }
    },
    onError: (error) => {
      console.error("Delete mutation error", error)
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 3000,
        })
      }
    },
    refetchQueries: [{ query: GET_ASSESSMENTS }],
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const assest = data.assessments

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  // Clickeable Name
  const nameTemplate = (rowData) => {
    return (
      <a
        style={{
          textDecoration: "underline",
          color: "var(--branded-light-green)",
          cursor: "pointer",
        }}
        onClick={(e) => {
          gaTrackingParams.payload = {
            button_name: "click_view_name",
            button_text: rowData.participant_name_full,
            button_type: "link_click",
            button_location: "/ths-admin/assessments",
          }
          gATracking(gaTrackingParams)

          e.preventDefault()
          navigate(`/participant/profile/${rowData.participant_id}`)
        }}
      >
        {rowData.participant_name_full}
      </a>
    )
  }

  // Format Date
  const dateTemplate = (rowData) => {
    const date = DateTime.fromFormat(
      rowData.date_created,
      "yyyy-MM-dd HH:mm:ss"
    )
    const dateWithoutTime = date.startOf("day")
    return dateWithoutTime.toFormat("MM/dd/yyyy")
  }

  const handleDownload = async () => {
    try {
      const USERS_URL = services.userURL()
      const DOWNLOAD_ASSESSMENT = `${USERS_URL}/reports/assessments/xlsx`
      const response = await axios.get(DOWNLOAD_ASSESSMENT, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${userCtx.token}`,
        },
      })
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `assessments.xlsx`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = (rowData) => {
    const assessment_id = rowData.assessment_id
    console.log("Deleting assessment with ID:", assessment_id)
    deleteAssessment({
      variables: { assessment_id },
      refetchQueries: [{ query: GET_ASSESSMENTS }],
    })
  }

  const cohortsfiltered = [
    { name: "All" },
    ...Array.from(
      new Set(assest.map((assestement) => assestement.organization_cohort_name))
    ).map((cohortName) => ({ name: cohortName })),
  ]

  const filteredAssessments =
    selectedCohort && selectedCohort.name !== "All"
      ? assest.filter(
          (assest) => assest.organization_cohort_name === selectedCohort.name
        )
      : assest

  return (
    <div className="p-col-12 p-md-3">
      <h2>Assessments</h2>
      <Toast ref={toast} />

      <div className="flex align-items-center gap-5 justify-content-between">
        {
          <Dropdown
            value={selectedCohort}
            onChange={(e) => setSelectedCohort(e.value)}
            options={cohortsfiltered}
            optionLabel="name"
            placeholder="Select a Cohort"
            className="w-full md:w-14rem mb-5"
          />
        }
        <Button
          style={{ backgroundColor: "var(--branded-dark-blue)" }}
          icon="pi pi-download"
          className="w-2 mb-5"
          label="Download"
          onClick={() => {
            gaTrackingParams.event = "download"
            gaTrackingParams.payload = {
              // organization_id: assest.organization_id,
              // cohort_id: assest.organization_cohort_id,
              file_name: "assessments",
              file_type: "PDF",
            }
            gATracking(gaTrackingParams)

            handleDownload()
          }}
        />
      </div>

      <DataTable
        value={filteredAssessments}
        paginator
        rows={10}
        columnResizeMode="fit"
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="organization_cohort_name"
          header="Cohort"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "125px", wordWrap: "break-word" }}
        />

        <Column
          sortable
          filter
          field="participant_name_full"
          header="Participant"
          body={nameTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="cohort_date_start"
          header="Assessment Date"
          body={dateTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
          headerStyle={{ whiteSpace: "pre-line" }}
        />
        <Column
          sortable
          field="total_score_satisfaction_life"
          header="Overall Life Satisfaction"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          headerStyle={{ whiteSpace: "pre-line" }}
        />
        <Column
          sortable
          field="total_score_resilience_stress"
          header="Overall Resilience to Stress"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          headerStyle={{ whiteSpace: "pre-line" }}
        />
        <Column
          sortable
          field="total_score_personal_performance"
          header="Overall Personal Performance"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          headerStyle={{ whiteSpace: "pre-line" }}
        />
        <Column
          sortable
          field="total_score_assessment"
          header="Overall Well Being"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          headerStyle={{ whiteSpace: "pre-line" }}
        />
        <Column
          header="Action"
          body={(rowData) => (
            <Button
              icon="pi pi-trash"
              label="Delete"
              onClick={() => {
                gaTrackingParams.event = "deleted"
                gaTrackingParams.payload = {
                  organization_id: rowData.organization_id,
                  content_id: rowData.assessment_id,
                  content_type: "assessment",
                  timestamp: date,
                }
                gATracking(gaTrackingParams)

                handleDelete(rowData)
              }}
              className="white-space-normal	overflow-hidden	"
              style={{ maxWidth: "200px", wordWrap: "break-word" }}
            />
          )}
        />
      </DataTable>
    </div>
  )
}
