import { useContext } from "react"
import { useParams, useNavigate, generatePath } from "react-router-dom"
import { useFormikContext } from "formik"

import ROUTES from "../../../components/Routes/routesConstants"
import PropTypes from "prop-types"

// Context
import { Button } from "primereact/button"
import { FormContext } from "../context/FormContext"

export default function BlockActionBtns({ block }) {
  const formik = useFormikContext()
  const form = useContext(FormContext)
  const navigate = useNavigate()

  const isValid = formik.isValid

  const { idTemplate, formPage, idCohort, idRef } = useParams()

  const handleNextPageJump = () => {
    form.handleSaveBlock(block, formik.values)

    const nextPage = () => {
      const formPageNext = Number(formPage) + 1

      if (idTemplate === "participant") {
        return generatePath(`/${ROUTES.REGISTRATION_FORM}`, {
          idTemplate,
          formPage: formPageNext,
          idCohort,
        })
      }

      if (idRef) {
        return generatePath(`/${ROUTES.GLOBAL_FORM_EDIT}`, {
          idTemplate,
          formPage: formPageNext,
          idRef,
        })
      }

      generatePath(`/${ROUTES.GLOBAL_FORM}`, {
        idTemplate,
        formPage: formPageNext,
      })
    }

    navigate(nextPage(), { replace: true })
  }

  const HandleNextBlock = () => {
    if (form.isLastBlock) {
      return (
        <Button
          type="submit"
          icon="pi pi-check"
          severity="success"
          className="gap-2 w-10rem h-4rem mt-2 justify-content-center"
          onClick={() => {
            const submit = true
            form.handleSaveBlock(block, formik.values, submit)
          }}
          disabled={!isValid}
        >
          Submit
        </Button>
      )
    }
    return (
      <Button
        iconPos="right"
        icon="pi pi-angle-double-right"
        onClick={handleNextPageJump}
        disabled={!isValid}
      >
        Next Block
      </Button>
    )
  }

  return (
    <div className="flex justify-between mt-4 gap-2">
      <HandleNextBlock />
    </div>
  )
}

// PropTypes validation
BlockActionBtns.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
}
