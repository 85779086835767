import React, { useState } from "react"
import { useMutation, gql } from "@apollo/client"
import _ from "lodash"
import { useNavigate } from "react-router-dom"

// Helpers
import convertAvgTime from "../../helpers/convertAvgTime"
import statusBodyTemplate from "./helpers/statusBodyTemplate"

// PR
import { Button } from "primereact/button"
import { Chips } from "primereact/chips"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

// Comps
import AddResource from "./components/AddResource"

import { LoaderMedium } from "../../components/Loaders"

const ADD_LESSON = gql`
  mutation AddLesson(
    $title: String!
    $description: String
    $tags: [String]
    $resources: [ResourceInput]
  ) {
    addLesson(
      title: $title
      description: $description
      tags: $tags
      resources: $resources
    ) {
      id
    }
  }
`

export default function AddLesson() {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [tags, setTags] = useState([])
  const [id, setId] = useState(0)

  const [editMode, setEditMode] = useState(false)
  const [editResource, setEditResource] = useState({})

  const [resources, setResources] = useState([])

  const navigate = useNavigate()

  const [addLesson, { data, loading, error }] = useMutation(ADD_LESSON, {
    variables: {
      title,
      tags,
      description,
      resources,
    },
    onCompleted: (data) => {
      console.log("New Lesson added: ", data)
      navigate("/list/lessons")
    },
  })

  const handleAddResource = (newResource) => {
    if (_.isEmpty(newResource.id)) {
      setId(id + 1)
      newResource.id = `new_${id}`
    }
    setResources([...resources, newResource])
  }

  const handleEditResource = (newResource, initialResourceId) => {
    const updatedResources = resources.map((resource) =>
      resource.id === initialResourceId ? newResource : resource
    )
    setResources(updatedResources)
    setEditMode(false)
  }

  const editRow = (rowData) => {
    setEditMode(true)
    setEditResource(rowData)
    // Scroll the AddResource section into view
    const addResourceSection = document.getElementById("addResourceSection")
    if (addResourceSection) {
      addResourceSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  const deleteRow = (rowData) => {
    const updatedResources = resources.filter(
      (resource) => resource.id !== rowData.id
    )
    setResources(updatedResources)
  }

  if (loading) return <LoaderMedium />

  return (
    <div>
      <h1>New Lesson</h1>
      <div className="flex flex-column gap-4 mb-5">
        <div className="flex flex-column">
          <label htmlFor="title">Title</label>
          <InputText
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="flex flex-column">
          <label htmlFor="input">Description</label>
          <InputTextarea
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
        <div className="flex flex-column">
          <label htmlFor="tags">Tags</label>
          <Chips
            value={tags}
            onChange={(e) => setTags(e.value)}
            separator=","
          />
          <small>Comma separated tags.</small>
        </div>
      </div>

      <Button
        label="Save"
        className="p-button-success"
        onClick={addLesson}
        disabled={
          _.isEmpty(title) || _.isEmpty(description) || _.isEmpty(resources)
        }
      />

      <Divider id="addResourceSection" />

      <AddResource
        handleAddResource={handleAddResource}
        handleEditResource={handleEditResource}
        editMode={editMode}
        editResource={editResource}
        setEditMode={setEditMode}
      />

      <Divider />

      <h2>Resource List</h2>

      <DataTable
        value={resources}
        dataKey="id"
        reorderableColumns
        reorderableRows
        tableStyle={{ minWidth: "50rem" }}
        onRowReorder={(e) => {
          setResources([...e.value])
        }}
      >
        <Column rowReorder style={{ width: "3rem" }} />
        <Column field="name" header="Name" style={{ width: "50%" }} />
        <Column
          field="type"
          header="Type"
          body={statusBodyTemplate}
          style={{ width: "20%" }}
        />
        <Column
          field="avgTime"
          header="Avg time"
          body={(rowData) => convertAvgTime(rowData.avgTime)}
          style={{ width: "20%" }}
        />
        <Column
          header="Actions"
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
          body={(rowData) => (
            <div className="flex justify-content-center gap-2">
              <Button
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={() => deleteRow(rowData)}
              />
              <Button
                icon="pi pi-pencil"
                className="p-button-primary"
                onClick={() => editRow(rowData)}
              />
            </div>
          )}
        />
      </DataTable>
    </div>
  )
}
