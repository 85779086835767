import React, { useEffect, useRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useQuery, gql, useMutation } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import { UserContext } from "../../context/userContext"
import ROUTES from "../../components/Routes/routesConstants"

import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"
//Ga
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

const GET_STAFF = gql`
  query Staff {
    staffAll {
      staff_id
      staff_name_full
      staff_email
      staff_status
      staff_credentials
      assigned_participants
    }
  }
`

const DELETE_STAFF = gql`
  mutation deleteStaff($staff_id: ID!) {
    deleteStaff(staff_id: $staff_id) {
      message
    }
  }
`

export default function CoachList() {
  const navigate = useNavigate()
  const toast = useRef(null)
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  const { loading, error, data } = useQuery(GET_STAFF, {
    fetchPolicy: "network-only",
  })

  const [
    deleteStaff,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_STAFF, {
    onCompleted: (data) => {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: data.deleteStaff.message,
      })
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      })
    },
    refetchQueries: [{ query: GET_STAFF }],
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const coaches = data.staffAll

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const action = ({ staff_id }) => {
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          //GA

          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            content_id: staff_id,
            content_type: "Coach",
            timestamp: date,
          }
          gATracking(gaTrackingParams)
          //GA
          deleteStaff({
            variables: { staff_id },
          })
        },
      },
    ]
    return (
      <SplitButton
        label="Edit"
        onClick={() => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            content_id: staff_id,
            content_type: "Coach",
            timestamp: date,
          }
          gATracking(gaTrackingParams)
          navigate(`/form/edit/staff/1/${staff_id}`)
        }}
        model={items}
      />
    )
  }

  return (
    <div className="p-col-12 p-md-3">
      <div className="flex gap-5 align-items-center">
        <h1>Coach List</h1>
        <Toast ref={toast} />
        <Button
          icon="pi pi-plus"
          className="mb-3"
          rounded
          onClick={() => navigate("/form/staff/1")}
          style={{
            backgroundColor: "var(--branded-dark-blue)",
            borderColor: "var(--branded-dark-blue)",
          }}
          severity="success"
          aria-label="Add"
        />
      </div>
      <DataTable
        value={coaches}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="staff_name_full"
          header="Name"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="staff_email"
          header="Email"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="staff_credentials"
          header="Credentials"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="staff_status"
          header="Status"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="assigned_participants"
          header="Assigned Participants"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          body={(row) => action(row)}
          header="Action"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>
    </div>
  )
}
