import React, { useContext } from "react"
import { gql, useQuery } from "@apollo/client"

import { Field } from "formik"
import { LoaderMedium } from "../../../../components/Loaders"

// PR
import { Dropdown } from "primereact/dropdown"

// Ctx
import { UserContext } from "../../../../context/userContext"
import { FormContext } from "../../context/FormContext"

const ORG_COHORT_LIST = gql`
  query organizationCohortsByStaff($staff_id: ID!) {
    organizationCohortsByStaff(staff_id: $staff_id) {
      organization_cohort_id
      organization_cohort_name
      course_id
      course_name
    }
  }
`

export default function TypeNoteOrganizationCohorId({ field }) {
  const userCtx = useContext(UserContext)

  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  const { loading, error, data } = useQuery(ORG_COHORT_LIST, {
    variables: { staff_id: userCtx.session_id },
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const cohortList = data?.organizationCohortsByStaff
    ? data.organizationCohortsByStaff.map((org) => {
        return {
          label: `${org.organization_cohort_name} / ${org.course_name}`,
          value: org.organization_cohort_id,
          course_id: org.course_id,
          course_name: org.course_name,
        }
      })
    : []

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta, form: formik }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && "*"}
            </label>
            <Dropdown
              disabled={isDisabled}
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onChange={({ value }) => {
                formik.setFieldValue(formikField.name, value)
                const course = cohortList.find((c) => c.value === value)
                formik.setFieldValue("course_id", course.course_id)
                formik.setFieldValue("course_name", course.course_name)
              }}
              onBlur={() => formik.setFieldTouched(formikField.name)}
              options={cohortList}
            />
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
