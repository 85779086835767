import React, { useContext } from "react"
import { Field } from "formik"

// Ctx
import { FormContext } from "../context/FormContext"

// PR
import { Password } from "primereact/password"

export default function TypePassword({ field, isEditMode }) {
  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] ||
      (isEditMode && field.field_disabled_on_edit)
    : false

  const isRequired = field.field_validation?.required?.[0] === "true"

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && <span style={{ color: "red" }}>*</span>}
            </label>
            <Password
              toggleMask
              disabled={isDisabled}
              name={formikField.name}
              id={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              onBlur={formikField.onBlur}
              invalid={meta.touched && meta.error}
            />
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
