import React, { useContext, useEffect } from "react"
import { gql, useLazyQuery } from "@apollo/client"

import { Field, useFormikContext } from "formik"
import { LoaderMedium } from "../../../../components/Loaders"

// PR
import { Dropdown } from "primereact/dropdown"

// Ctx
import { FormContext } from "../../context/FormContext"

const LESSON_LIST = gql`
  query lessonsByCourse($course_id: ID!) {
    lessonsByCourse(course_id: $course_id) {
      id
      title
      name
    }
  }
`

export default function TypeNoteLessonId({ field }) {
  const form = useContext(FormContext)
  const formik = useFormikContext()

  const courseId = formik.values.course_id || null

  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  const [getLessons, { loading, error, data }] = useLazyQuery(LESSON_LIST, {
    variables: {
      course_id: courseId,
    },
  })

  useEffect(() => {
    if (courseId) {
      getLessons()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  if (!data) return null

  const lessonList = data?.lessonsByCourse
    ? data.lessonsByCourse.map((lesson) => {
        return {
          label: lesson.title,
          value: lesson.id,
        }
      })
    : []

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta, form: formik }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && "*"}
            </label>
            <Dropdown
              disabled={isDisabled || !courseId}
              id={field.field_name}
              name={formikField.name || []}
              value={formikField.value}
              onChange={({ value }) => {
                formik.setFieldValue(formikField.name, value)
                const lesson = lessonList.find((l) => l.value === value)
                formik.setFieldValue("lesson_name", lesson.label)
              }}
              onBlur={() => formik.setFieldTouched(formikField.name)}
              options={lessonList}
            />
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
