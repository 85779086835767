import React from "react"

import CreatedBy from "./CreatedBy"
import ParticipantId from "./ParticipantId"
import StaffId from "./StaffId"
import SelectedOrganizationId from "./SelectedOrganizationId"
import SelectedCourseId from "./SelectedCourseId"
import ParticipantCoachingRegistrationId from "./ParticipantCoachingRegistrationId"

import NoteOrganizationCohorId from "./NoteOrganizationCohorId"
import NoteParticipantId from "./NoteParticipantId"
import NoteLessonId from "./NoteLessonId"

export default function TypeSys(props) {
  switch (props.field.idField) {
    case "created_by":
      return <CreatedBy {...props} />
    case "participant_id":
      return <ParticipantId {...props} />
    case "participant_coaching_registration_id":
      return <ParticipantCoachingRegistrationId {...props} />
    case "staff_id":
      return <StaffId {...props} />
    case "organization_id":
    case "selected_organization_id":
      return <SelectedOrganizationId {...props} />
    case "course_id":
    case "cohort_course_id":
      return <SelectedCourseId {...props} />
    case "note_organization_cohort_id":
      return <NoteOrganizationCohorId {...props} />
    case "note_participant_id":
      return <NoteParticipantId {...props} />
    case "note_lesson_id":
      return <NoteLessonId {...props} />
    default:
      console.log("Sys Field Type Not Found:", props.field)
      return null
  }
}
