import React, { useContext } from "react"

import StaffUserHome from "./components/StaffUserHome"
import SysAdminHome from "./components/SysAdminHome"
import ThsAdminHome from "./components/ThsAdminHome"
import AuthUserHome from "./components/AuthUserHome"
import ParticipantHome from "./components/ParticipantHome"

import { UserContext } from "../../context/userContext"

export default function Home() {
  const userCtx = useContext(UserContext)

  switch (userCtx.role_id) {
    case "staff-user":
      return <StaffUserHome />
    case "sys-admin":
      return <SysAdminHome />
    case "ths-admin":
      return <ThsAdminHome />
    case "auth-user":
      return <AuthUserHome />
    case "participant":
      return <ParticipantHome />
    default:
      return <div>Not Authorized</div>
  }
}
