import React, { useContext, useRef, useEffect } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import { UserContext } from "../../../context/userContext"

import { useQuery, gql } from "@apollo/client"
import ROUTES from "../../../components/Routes/routesConstants"

import { LoaderMedium } from "../../../components/Loaders"
import { Card } from "primereact/card"
import { Button } from "primereact/button"

//GA
import gATracking from "../../../helpers/gaTracking"

const ADMIN_STATS = gql`
  query adminStats {
    adminStats {
      total_participants_active
      total_participants_awaiting
      total_staff_active
      total_organizations_active
      total_cohorts_active
      total_cohorts_due
      total_assessments
      avg_assessment_score
      mode_assessment_score
      max_assessment
      min_assessment
    }
  }
`

export default function ThsAdminHome() {
  const navigate = useNavigate()
  const location = useLocation()
  const hasLoggedRef = useRef(false)
  const userCtx = useContext(UserContext)

  const { loading, error, data } = useQuery(ADMIN_STATS, {
    fetchPolicy: "network-only",
  })

  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }

  useEffect(() => {
    if (location.state?.fromLogin && !hasLoggedRef.current) {
      gaTrackingParams.event = "login_successfull"

      gATracking(gaTrackingParams)

      hasLoggedRef.current = true
    }
  }, [location.state])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const stats = data.adminStats

  return (
    <div>
      <h1 className="mb-5">Welcome Admin</h1>
      <hr />
      <section className="flex flex-wrap gap-5">
        <h2 className="-mb-1">Assessments</h2>

        <section className="flex  gap-5">
          <Card
            onClick={() => {
              gaTrackingParams.event = "click_button"
              gaTrackingParams.payload = {
                button_name: "click_scorecard_total_assessments",
                button_text: "Total Assessments",
                button_type: "click_scorecard",
                button_location: "ThsHomepage",
              }
              gATracking(gaTrackingParams)

              navigate(`ths-admin/assessments`)
            }}
            className="w-18rem shadow-3 cursor-pointer hover:bg-gray-200"
            style={{
              border: "solid 1px #3175BB",
            }}
          >
            <div className="flex align-items-center justify-content-between">
              <h3>Total Assesments</h3>
              <i className=" pi pi-info-circle text-green-500 text-xl"></i>
            </div>
            <h2 className="text-center">{stats.total_assessments}</h2>
          </Card>
          <Card
            className="w-18rem shadow-3"
            style={{
              border: "solid 1px #3175BB",
            }}
          >
            {" "}
            <div className="flex align-items-center justify-content-between">
              <h3>Average Score</h3>
              <i className=" pi pi-percentage text-green-500 text-xl"></i>
            </div>
            <h2 className="text-center">{stats.avg_assessment_score} pts</h2>
          </Card>
          <Card
            className="w-18rem shadow-3"
            style={{
              border: "solid 1px #3175BB",
            }}
          >
            {" "}
            <div className="flex align-items-center  justify-content-between">
              <h3>Mode Score</h3>
              <i className=" pi pi-info-circle text-green-500	 text-xl"></i>
            </div>
            <h2 className="text-center">{stats.mode_assessment_score} pts</h2>
          </Card>
          <Card
            className="w-18rem shadow-3"
            style={{
              border: "solid 1px #3175BB",
            }}
          >
            {" "}
            <div className="flex align-items-center justify-content-around">
              <h3>Min Score</h3>
              <h3>Max Score</h3>
            </div>
            <h2 className="text-center">
              {stats.min_assessment}-{stats.max_assessment}
            </h2>
          </Card>
        </section>
      </section>
      <hr className="my-5" />
      <div className="flex justify-content-between">
        <div className="flex align-items-center gap-3">
          <i className="pi pi-users text-green-500 text-3xl"></i>

          <h2 className="">Participants</h2>
          <Button
            label="View All Participants"
            style={{
              backgroundColor: "white",
              border: "none",
              color: "var(--branded-light-green)",
            }}
            onClick={() => {
              gaTrackingParams.event = "click_button"
              gaTrackingParams.payload = {
                button_name: "link_click_view_all_participants",
                button_text: "View All Participants",
                button_type: "click_link",
                button_location: "ThsHomepage",
              }
              gATracking(gaTrackingParams)

              navigate(`../../${ROUTES.PARTICIPANT_LIST_ALL}`)
            }}
          />
        </div>
        <div
          className="flex align-items-center gap-3"
          style={{ marginRight: "20rem" }}
        >
          <i className="pi pi-th-large text-green-500 text-xl"></i>
          <h2>Cohorts and Organizations</h2>
        </div>
      </div>
      <div className="flex gap-5">
        <Card
          className="w-18rem shadow-3 cursor-pointer hover:bg-gray-200"
          onClick={() => {
            gaTrackingParams.event = "click_button"
            gaTrackingParams.payload = {
              button_name: "click_scorecard_participants_awaiting",
              button_text: "Awaiting",
              button_type: "click_scorecard",
              button_location: "ThsHomepage",
            }
            gATracking(gaTrackingParams)
            navigate(`list/participant`)
          }}
          style={{
            border: "solid 1px #3175BB",
          }}
        >
          <div className="flex justify-content-between align-items-center">
            <h3>Awaiting:</h3>
            <i className="pi pi-clock text-green-500 text-xl"></i>
          </div>
          <div className="flex justify-content-around">
            <h2 className="text-center">{stats.total_participants_awaiting}</h2>
          </div>
        </Card>
        <Card
          className="w-18rem shadow-3 cursor-pointer  hover:bg-gray-200"
          onClick={() => {
            gaTrackingParams.event = "click_button"
            gaTrackingParams.payload = {
              button_name: "click_scorecard_participants_active",
              button_text: "Active",
              button_type: "click_scorecard",
              button_location: "ThsHomepage",
            }
            gATracking(gaTrackingParams)
            navigate(`ths-admin/coaching-asignments`)
          }}
          style={{
            border: "solid 1px #3175BB",
          }}
        >
          <div className="flex justify-content-between align-items-center">
            <h3>Active:</h3>
            <i className="pi pi-info-circle text-green-500 text-xl"></i>
          </div>
          <div className="flex justify-content-around">
            <h2 className="text-center">{stats.total_participants_active}</h2>
          </div>
        </Card>
        <Card
          style={{
            border: "solid 1px #3175BB",
          }}
          className="flex flex-column justify-content-between h-13rem w-18rem"
        >
          <div className="flex align-items-center gap-1 -mt-2">
            <h3>Active Coaches: </h3>
            <p>{stats.total_staff_active}</p>
          </div>
          <div className="flex align-items-center gap-1">
            <h3>Active Organizations: </h3>
            <p>{stats.total_organizations_active}</p>
          </div>
        </Card>
        <Card
          style={{
            border: "solid 1px #3175BB",
          }}
          className="flex flex-column justify-content-between h-13rem w-18rem"
        >
          <div className="flex align-items-center gap-1">
            <h3>Active Cohorts: </h3>
            <p>{stats.total_cohorts_active}</p>
          </div>
          <div className="flex align-items-center gap-1">
            <h3>Due Cohorts: </h3>
            <p>{stats.total_cohorts_due}</p>
          </div>
        </Card>
      </div>
    </div>
  )
}
/*

total_staff_active
total_organizations_active
total_cohorts_active
total_cohorts_due
*/
