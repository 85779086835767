import React, { useContext, useEffect } from "react"
import { InputTextarea } from "primereact/inputtextarea"
import { InputText } from "primereact/inputtext"

import { AddLessonCtx } from "../../context/addLessonCtx"
import FileUploader from "../FileUploader"

export default function TypeFile() {
  const context = useContext(AddLessonCtx)
  const { title, description, src } = context.newResource.content
  useEffect(() => {
    context.handleOnChange("htmlTag", "file")
  }, [])
  return (
    <div>
      <div className="flex flex-column">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          name="title"
          value={title}
          onChange={(e) =>
            context.handleOnChange(e.target.name, e.target.value)
          }
        />
      </div>
      <div className="flex flex-column mt-3">
        <FileUploader type="file" />
      </div>
      <div className="flex flex-column">
        <label htmlFor="description">Description</label>
        <InputTextarea
          id="description"
          name="description"
          value={description}
          rows={5}
          cols={30}
          onChange={(e) =>
            context.handleOnChange(e.target.name, e.target.value)
          }
        />
      </div>
    </div>
  )
}
