import React, { useState, useRef, useContext } from "react"
import { UserContext } from "../../context/userContext"
import { Formik, Form } from "formik"
import logo from "../../assets/images/life-xt-logo-big.png"
import { auth } from "../../firebase"
import * as Yup from "yup"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { Button } from "primereact/button"
import { Link, useNavigate } from "react-router-dom"

import { Dialog } from "primereact/dialog"
import * as services from "../../config/servicesURL"
import { Toast } from "primereact/toast"
import axios from "axios"

const LoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
})

export default function Login() {
  const [authError, setAuthError] = useState(null)

  // Recover Password
  const [visible, setVisible] = useState(false)
  const [emailRecover, setEmailRecover] = useState("")
  const toast = useRef(null)
  const userCtx = useContext(UserContext)
  const navigate = useNavigate()

  const resetPassword = async () => {
    try {
      const USERS_URL = services.userURL()

      const RESET_PASSWORD = `${USERS_URL}/auth/password-reset`

      const response = await axios.post(
        RESET_PASSWORD,
        {
          email: emailRecover,
        },
        {
          headers: {
            Authorization: `Bearer ${userCtx.token}`,
          },
        }
      )
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Your email to change your password has been sent ",
        })
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There was an error sending the email",
        })
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `There was an error sending the email`,
      })
      console.error(error)
    }
  }

  // GA

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="w-full w-6 h-5">
        <div className="text-center mb-5">
          <img src={logo} alt="LifeXT" height="80" className="mb-3" />
        </div>
        <Toast ref={toast} />

        <Formik
          className="login-form"
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values) => {
            const { username: email, password } = values
            try {
              await auth.signInWithEmailAndPassword(email, password)
              navigate("/", { state: { fromLogin: true } })
            } catch (error) {
              setAuthError(
                "Oops! That username or password doesn't match. Please try again"
              )
              console.log(error)
            }
          }}
        >
          {(formik) => {
            const { errors, touched, values, setFieldValue } = formik
            return (
              <Form>
                <div className="flex flex-column align-items-center gap-5 mt-7">
                  <div className="p-float-label">
                    <InputText
                      id="username"
                      name="username"
                      required
                      value={values.username}
                      onChange={({ target: { value } }) =>
                        setFieldValue("username", value)
                      }
                    />
                    <label>Username</label>
                    {touched.username && errors.username && (
                      <div style={{ color: "#ff678c" }}>{errors.username}</div>
                    )}
                  </div>
                  <div className="p-float-label">
                    <Password
                      inputId="password"
                      name="password"
                      value={values.password}
                      onChange={({ target: { value } }) =>
                        setFieldValue("password", value)
                      }
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  {touched.password && errors.password && (
                    <div style={{ color: "#ff678c" }}>{errors.password}</div>
                  )}
                  {authError && (
                    <div style={{ color: "#ff678c" }}>{authError}</div>
                  )}

                  <Button type="submit" className="mt-1">
                    Login
                  </Button>
                  <Link
                    style={{
                      color: "var(--branded-light-green)",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    to={"#"}
                    onClick={() => setVisible(true)}
                  >
                    Forgot your Password
                  </Link>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>

      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        header="Recover your Password"
        modal
        style={{ width: "50vw" }}
      >
        <div className="flex flex-column gap-5 justify-content-center align-items-center">
          <h3>Please enter your Email</h3>

          <InputText
            id="recoverEmail"
            name="Email"
            onChange={(e) => setEmailRecover(e.target.value)}
          ></InputText>
          <Button
            onClick={(e) => {
              e.preventDefault()
              resetPassword()
            }}
            label="Send Email"
          ></Button>
        </div>
      </Dialog>
    </div>
  )
}
