import React, { useState, useEffect, useContext, useRef } from "react"
import { UserContext } from "../../context/userContext"
import { useParams } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { LoaderMedium } from "../../components/Loaders"
import { Toast } from "primereact/toast"
import axios from "axios"

import * as services from "../../config/servicesURL"

// Prime React
import { Button } from "primereact/button"
import { Card } from "primereact/card"

const GET_STAFF = gql`
  query Staff($staff_id: ID!) {
    staff(staff_id: $staff_id) {
      staff_id
      staff_name_full
      staff_email
      staff_status
      staff_credentials
      staff_profile_image
      staff_profile_image_link_400x400
      staff_role
    }
  }
`

export default function StaffProfile() {
  const userCtx = useContext(UserContext)
  const { idStaff } = useParams()
  const navigate = useNavigate()
  const toast = useRef(null)

  //Query

  const { loading, error, data } = useQuery(GET_STAFF, {
    variables: { staff_id: idStaff },
    fetchPolicy: "network-only",
    skip: !idStaff,
  })
  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const staff = data.staff
  const resetPassword = async () => {
    try {
      const USERS_URL = services.userURL()

      const RESET_PASSWORD = `${USERS_URL}/auth/password-reset/${idStaff}`

      const response = await axios.get(RESET_PASSWORD, {
        headers: {
          Authorization: `Bearer ${userCtx.token}`,
        },
      })
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Your email to change your password has been sent ",
        })
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There was an error sending the email",
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <section>
      <h1>Profile</h1>
      <Toast ref={toast} />

      <div className="flex flex-column justify-content-around align-items-center">
        <Card
          className=" shadow-2 w-30rem flex flex-column justify-content-around p-2"
          style={{ border: "solid 1px #1e529c" }}
        >
          <div className="flex justify-content-center">
            <img
              className="w-20rem h-20rem"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              src={staff.staff_profile_image_link_400x400}
            />
          </div>
          <h2>{staff.staff_name_full}</h2>
          <div className="flex gap-2 align-items-center">
            <h4>Email: </h4>
            <p>{staff.staff_email}</p>
          </div>

          <div className="flex gap-2 align-items-center">
            <h4>Credentials: </h4>
            <p>{staff.staff_credentials}</p>
          </div>
          <div className="flex gap-2 align-items-center">
            <h4>Role: </h4>
            <p>{staff.staff_role}</p>
          </div>
          <div className="flex justify-content-between mt-4 align-items-center">
            <Button
              onClick={() =>
                navigate(`../form/edit/coach_profile/1/${userCtx.session_id}`)
              }
            >
              Edit Profile
            </Button>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                resetPassword()
              }}
            >
              Reset Password
            </a>
          </div>
        </Card>
      </div>
    </section>
  )
}
