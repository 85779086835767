import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import _ from "lodash"
import { DateTime } from "luxon"

// Form Ctx
import { FormContext } from "./context/FormContext"
import { UserContext } from "../../context/userContext"

// Apollo
import { useQuery, useLazyQuery, gql } from "@apollo/client"

// Components
import FormContainer from "./FormContainer"
import { LoaderMedium } from "../../components/Loaders"

// Nav
import FormNav from "./nav/FormNav"
import axios from "axios"

// services
import * as services from "../../config/servicesURL"

//GA
import gATracking from "../../helpers/gaTracking"
import {
  GET_STAFF_IMG,
  GET_PARTICIPANTS_IMG,
} from "../../layout/queries/queryHeader"

const GET_FORM = gql`
  query GetForm($idTemplate: ID!) {
    template(idTemplate: $idTemplate) {
      template_name
      template_title
      template_description
      total_blocks
      blocks {
        index
        block_name
      }
    }
  }
`

const GET_COHORT = gql`
  query GetCohortFromRegistration($organization_cohort_id: ID!) {
    cohort: organizationCohortRegistration(
      organization_cohort_id: $organization_cohort_id
    ) {
      organization_name
      organization_id
      organization_cohort_name
      course_name
      course_id
      cohort_number_seats
      cohort_number_seats_available
      cohort_date_start
      cohort_date_end
    }
  }
`

export default function GlobalForm() {
  const [errorMsg, setErrorMsg] = useState(null)
  const [errorOnSubmit, setErrorOnSubmit] = useState(false)
  const { formPage, idTemplate, idCohort, idRef } = useParams()

  const [globalValues, setGlobalValues] = useState({})
  const [submit, setSubmit] = useState(false)
  const [triggerSubmit, setTriggerSubmit] = useState(false)

  const [blocks, setBlocks] = useState([])

  const [blocksRequired, setBlocksRequired] = useState([]) // ???
  const [blocksSaved, setBlocksSaved] = useState({})
  const [blocksEdited, setBlocksEdited] = useState({})

  const navigate = useNavigate()
  const userCtx = useContext(UserContext)

  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }

  const REGISTRATION_FORM = idTemplate === "participant"

  const { loading, data, error } = useQuery(GET_FORM, {
    fetchPolicy: "network-only",
    variables: {
      idTemplate,
    },
    onCompleted: (data) => {
      const sortedBlocks = _.sortBy(data.template.blocks, ["index"])

      const initBlocksSaved = _.reduce(
        data.template.blocks,
        (acc, block) => {
          return {
            ...acc,
            [block.block_name]: false,
          }
        },
        {}
      )

      const initBlocksRequired = _.map(
        sortedBlocks,
        (block) => block.block_name
      )

      setBlocksSaved(initBlocksSaved)
      setBlocksRequired(initBlocksRequired)
      setBlocks(sortedBlocks)
    },
  })

  const [
    getCohort,
    { loading: loadingCohort, error: errorCohort, data: dataCohort },
  ] = useLazyQuery(GET_COHORT, {
    variables: {
      organization_cohort_id: idCohort,
    },
    onCompleted: (data) => {
      if (!data?.cohort) {
        setErrorMsg("Cohort not found.")
        navigate("/error", { state: { errorMessage: "Cohort not found." } })
      }
    },
    onError: (error) => {
      setErrorMsg(error.message)
      navigate("/error", { state: { errorMessage: error.message } })
    },
  })

  const [refetchParticipantsImg] = useLazyQuery(GET_PARTICIPANTS_IMG)
  const [refetchStaffImg] = useLazyQuery(GET_STAFF_IMG)

  useEffect(() => {
    if (REGISTRATION_FORM) {
      getCohort()
    }
  }, [idTemplate])

  useEffect(() => {
    if (dataCohort) {
      gaTrackingParams.event = "participant_registration_begin"
      gaTrackingParams.payload = {
        user_status: "inactive",
        organization_id: dataCohort.cohort.organization_id,
        cohort_id: idCohort,
      }
      gATracking(gaTrackingParams)
    }
  }, [dataCohort])

  const handleSaveBlock = (blockId, values, submit = false) => {
    setGlobalValues((prevValues) => ({
      ...prevValues,
      [blockId]: values,
    }))
    setBlocksEdited((prevBlocksEdited) => ({
      ...prevBlocksEdited,
      [blockId]: false,
    }))
    setBlocksSaved((prevBlocksSaved) => ({
      ...prevBlocksSaved,
      [blockId]: true,
    }))
    if (submit) {
      setTriggerSubmit(true)
    }
  }

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit()
    }
    // CK THIS***
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSubmit])

  const handleEditBlock = (blockId) => {
    setBlocksSaved((prevBlocksSaved) => ({
      ...prevBlocksSaved,
      [blockId]: false,
    }))
    return setBlocksEdited((prevBlocksEdited) => ({
      ...prevBlocksEdited,
      [blockId]: true,
    }))
  }

  const handleSubmit = async () => {
    setSubmit(true)

    let postBody = {
      template_id: idTemplate,
      values: globalValues,
    }
    if (REGISTRATION_FORM) {
      postBody.template_id = "participant_registration"
    }

    if (REGISTRATION_FORM) {
      postBody.organization_cohort_id = idCohort || "not-set"
      postBody.course_id = dataCohort?.cohort?.course_id || "not-set"
    }

    if (idTemplate === "staff") {
      postBody.values.staff.staff_id = idRef
    }

    if (idTemplate === "coach_profile") {
      postBody.values.coach_profile.staff_id = idRef
    }

    if (idTemplate === "organization") {
      postBody.values.organization.organization_id = idRef
    }

    if (idTemplate === "participant") {
      postBody.template_id = "participant_registration"
    }

    try {
      const cloudFunctionsURL = services.cloudFunctionsURL()

      let postURL = ""

      if (!_.isEmpty(idRef)) {
        postURL = `${cloudFunctionsURL}/formEdit`
      } else {
        postURL = `${cloudFunctionsURL}/formSubmit`
      }

      const response = await axios.post(postURL, postBody, {
        headers: {
          Authorization: "Bearer " + userCtx.token,
        },
      })

      if (response.status === 200) {
        if (idTemplate === "profile" && userCtx?.session_id) {
          console.log("Refetch participant image triggered.")
          await refetchParticipantsImg({
            skip: _.isEmpty(userCtx?.session_id),
            variables: { participant_id: userCtx.session_id },
          })

          if (userCtx.role_id !== "auth-user") {
            const redirectURL = `/list/all-participants`
            setSubmit(false)
            return navigate(redirectURL)
          }
        }
        if (idTemplate === "participant") {
          gaTrackingParams.event = "participant_registration_end"
          gaTrackingParams.payload = {
            user_status: "awaiting",
            organization_id: dataCohort.cohort.organization_id,
            cohort_id: idCohort,
          }

          gATracking(gaTrackingParams)
        }
        if (idTemplate === "coach_profile" && userCtx?.session_id) {
          console.log("Refetch staff image triggered.")
          await refetchStaffImg({
            skip: _.isEmpty(userCtx?.session_id),
            variables: { staff_id: userCtx.session_id },
          })
        }

        setSubmit(false)
        const newUrl = response?.data?.redirect
          ? `/${response.data.redirect}`
          : "/"
        navigate(newUrl)
      }

      setSubmit(false)
      setTriggerSubmit(false)
    } catch (error) {
      setSubmit(false)
      setTriggerSubmit(false)
      setErrorOnSubmit(true)
      navigate("/error", {
        state: {
          errorMessage: error?.response?.data?.error,
        },
      })
    }
  }

  const blockCurrent =
    blocks.find((block) => block.index === Number(formPage)) || {}

  if (loading || submit) return <LoaderMedium />
  if (error || errorCohort) {
    return <p>Error getting form data. {errorMsg}</p>
  }

  if (errorOnSubmit) return <p>Error on submit.</p>

  function FormHead() {
    if (REGISTRATION_FORM) {
      const formattedStartDate = DateTime.fromISO(
        dataCohort.cohort.cohort_date_start
      ).toFormat("MM/dd/yyyy")
      const formattedEndDate = DateTime.fromISO(
        dataCohort.cohort.cohort_date_end
      ).toFormat("MM/dd/yyyy")

      return (
        <div className="bg-cyan-50 p-3 border-gray-300 border-1 border-round border-x-none border-top-none shadow-1">
          <h1 className="mt-0">
            {dataCohort.cohort.organization_name}:{" "}
            {dataCohort.cohort.organization_cohort_name}
          </h1>
          <h3>Course: {dataCohort.cohort.course_name}</h3>
          <p>Starting Date: {formattedStartDate}</p>
          <p>End Date: {formattedEndDate}</p>
        </div>
      )
    }
    return (
      <div>
        <h2>{data.template.template_title}</h2>
        <p>{data.template.template_description}</p>
      </div>
    )
  }

  const formContextValue = {
    handleSaveBlock,
    handleEditBlock,
    globalValues,
    blocks,
    blocksRequired,
    blocksSaved,
    blocksEdited,
    blockCurrent,
    isLastBlock: data.template.total_blocks === Number(formPage),
  }

  return (
    <FormContext.Provider value={formContextValue}>
      <div>
        <FormHead />
        <div className="flex overflow-hidden mt-6 gap-5">
          <div className="flex-none flex max-w-14rem">
            <FormNav />
          </div>
          <div className="flex-grow-1 flex">
            <FormContainer />
          </div>
        </div>
      </div>
    </FormContext.Provider>
  )
}
