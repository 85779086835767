import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"

import { LoaderMedium } from "../../../components/Loaders"

import FieldBuilder from "./FieldBuilder"
import BlockActionBtns from "./BlockActionBtns"

const GET_BLOCK = gql`
  query BlockPerPage($idTemplate: ID!, $page: Int) {
    template(idTemplate: $idTemplate, page: $page) {
      blocks {
        idBlock
        block_name
        block_title
        total_fields
        index
        fields {
          idField
          description
          field_conditional
          field_label
          field_disabled_on_edit
          field_options {
            label
            value
          }
          field_name
          field_type
          field_description
          field_default_value
          field_validation {
            required
          }
        }
      }
    }
  }
`

export default function BlockBuilder() {
  const { idTemplate, formPage } = useParams()

  const { loading, data } = useQuery(GET_BLOCK, {
    fetchPolicy: "network-only",
    variables: { idTemplate, page: Number(formPage) },
  })

  if (loading) return <LoaderMedium />

  const block = data?.template.blocks[0]

  return (
    <div>
      <h2>{block.block_title}</h2>
      <FieldBuilder fields={block.fields} />
      <BlockActionBtns block={block.block_name} />
    </div>
  )
}
