import React, { useContext, useEffect } from "react"
import _ from "lodash"
import { DateTime } from "luxon"
import { Field } from "formik"
import { Calendar } from "primereact/calendar"

import { useFormikContext } from "formik"
import { FormContext } from "../context/FormContext"

export default function TypeDate({ field }) {
  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent
  const formik = useFormikContext()

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  const isAgeVerification = field.field_name === "demo_dob"

  // Calculate 13 years ago from today
  const thirteenYearsAgo = DateTime.now().minus({ years: 13 }).toJSDate()
  const maxDate = isAgeVerification ? thirteenYearsAgo : null

  const isEmptyDate = !_.isDate(formik.values[field.field_name])

  useEffect(() => {
    if (isAgeVerification && isEmptyDate) {
      formik.setFieldValue(field.field_name, thirteenYearsAgo)
    }
  }, [])

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta, form }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && <span style={{ color: "red" }}>*</span>}
            </label>
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            <Calendar
              name={formikField.name}
              disabled={isDisabled}
              value={formikField.value} // Set default value to 13 years ago
              onChange={formikField.onChange}
              maxDate={maxDate}
            />
            {meta.touched && meta.error && (
              <div className="red-600">{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
