import React, { useContext } from "react"
import { UserContext } from "../../../context/userContext"

import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
//GA Analitycs
import gATracking from "../../../helpers/gaTracking"
import getTime from "../../../helpers/getActualTime"

const RenderResourceList = ({ resourceList }) => {
  const millisToMinutes = (millis) => {
    return Math.floor(millis / (1000 * 60))
  }
  const userCtx = useContext(UserContext)
  const getActualTime = getTime()
  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  return (
    <div className="flex flex-column px-5 py-3">
      {resourceList.map((item) => {
        // convert from ms to min
        const time = () => {
          if (item.avgTime) {
            return `Estimated time: ${millisToMinutes(item.avgTime)}min`
          }
        }
        switch (item.type) {
          case "text": {
            return (
              <Card key={item.id} title={item.title} className="mb-3">
                <p>{item.content.value}</p>
              </Card>
            )
          }
          case "audio": {
            return (
              <Card key={item.id} title={item.title} className="mb-3">
                <h3 style={{ marginTop: 0 }}>{item.content.title}</h3>
                <audio controls style={{ width: "100%", maxWidth: "100%" }}>
                  <source src={item.content.src} type="audio/mpeg" />
                  Tu navegador no admite el elemento de audio.
                </audio>
                <p>{item.content.description}</p>
                <div style={{ textAlign: "right" }}>
                  <i className="pi pi-clock" style={{ fontSize: "1rem" }} />{" "}
                  {time()}
                </div>
              </Card>
            )
          }
          case "video": {
            const thumbnail =
              item.content.thumbnail &&
              item.content.thumbnail !== "File does not exist"
                ? item.content.thumbnail
                : undefined
            return (
              <Card key={item.id} title={item.title} className="mb-3">
                <h3 style={{ marginTop: 0 }}>{item.content.title}</h3>
                <video
                  controls
                  style={{ width: "100%", maxWidth: "100%" }}
                  poster={thumbnail}
                  onPlay={(e) => {
                    //GA Tracking
                    gaTrackingParams.event = "video_play"
                    gaTrackingParams.payload = {
                      video_title: item.content.title,
                      video_duration: Math.round(e.target.duration),
                      date: getActualTime,
                    }
                    gATracking(gaTrackingParams)
                    //end GA Tracking
                  }}
                  onEnded={(e) => {
                    //GA Tracking
                    gaTrackingParams.event = "video_ended"
                    gaTrackingParams.payload = {
                      video_title: item.content.title,
                      video_duration: Math.round(e.target.duration),
                      date: getActualTime,
                    }
                    gATracking(gaTrackingParams)
                    //end GA Tracking
                  }}
                  onPause={(e) => {
                    //GA Tracking
                    gaTrackingParams.event = "video_pause"
                    gaTrackingParams.payload = {
                      video_title: item.content.title,
                      video_duration: Math.round(e.target.duration),
                      video_current_time: Math.round(e.target.currentTime),
                      date: getActualTime,
                    }
                    gATracking(gaTrackingParams)
                    //end GA Tracking
                  }}
                >
                  <source src={item.content.src} type="video/mp4" />
                  Your browser does not support the video element.
                </video>
                <p>{item.content.description}</p>
                <div style={{ textAlign: "right" }}>
                  <i className="pi pi-clock" style={{ fontSize: "1rem" }} />{" "}
                  {time()}
                </div>
              </Card>
            )
          }
          case "divider":
            return <Divider key={item.id} />
          case "title": {
            const TitleTag = item.content.htmlTag || "h1" // Default to h1 if htmlTag is not specified
            return (
              <TitleTag className="flex justify-content-center my-5">
                {item.content.title}
              </TitleTag>
            )
          }
          case "editor": {
            return (
              <Card key={item.id} title={item.title} className="mb-3">
                <div dangerouslySetInnerHTML={{ __html: item.content.value }} />
                <div style={{ textAlign: "right" }}>
                  <i className="pi pi-clock" style={{ fontSize: "1rem" }} />{" "}
                  {time()}
                </div>
              </Card>
            )
          }
          default:
            return null
        }
      })}
    </div>
  )
}

export default RenderResourceList
