import React, { useContext } from "react"
import { Field } from "formik"

// Ctx
import { FormContext } from "../context/FormContext"

import { InputNumber as Input } from "primereact/inputnumber"

export default function TypeText({ field, isEditMode }) {
  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] ||
      (isEditMode && field.field_disabled_on_edit)
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  return (
    <Field name={field.field_name}>
      {({ field: formikField, form, meta }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && <span style={{ color: "red" }}>*</span>}
            </label>
            <Input
              disabled={isDisabled}
              name={formikField.name}
              id={formikField.name}
              value={formikField.value}
              pattern="\d+"
              onChange={({ value }) => {
                const newValue = value === null ? 0 : value
                form.setFieldValue(formikField.name, newValue)
              }}
              onBlur={formikField.onBlur}
              autoComplete="off"
              invalid={meta.touched && meta.error}
            />
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
