import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import axios from "axios"

import { UserContext } from "../../context/userContext"

import * as services from "../../config/servicesURL"

import { PanelMenu } from "primereact/panelmenu"
import { Button } from "primereact/button"
import { BreadCrumb } from "primereact/breadcrumb"
import { InputTextarea } from "primereact/inputtextarea"

import { LoaderMedium, LoaderProgressBar } from "../../components/Loaders"
import RenderResourceList from "./components/RenderResourceList"

//GA
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"

const GET_LESSON = gql`
  query GetLesson($participant_id: ID!, $idCourse: ID!, $idLesson: ID!) {
    participant(participant_id: $participant_id) {
      organization_cohort_id
      organization_id
      participant_coaching_registration_id
      staff_id
    }
    course(id: $idCourse) {
      id
      title
      lessons {
        id
        title
      }
    }
    lesson(id: $idLesson) {
      title
      description
      resources {
        avgTime
        content {
          description
          htmlTag
          src
          title
          value
          thumbnail
        }
        description
        id
        type
      }
    }
    log: participantLessonLogByParticipantId(participant_id: $participant_id) {
      course_id
      course_lesson_id
      course_lesson_complete
      course_lesson_reflection
    }
  }
`

export default function ViewLesson() {
  const userCtx = useContext(UserContext)
  const { idCourse, idLesson } = useParams()
  const navigate = useNavigate()
  const date = getActualTime()
  //Ga Tracking
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }
  //End Ga Tracking

  const { loading, error, data, refetch } = useQuery(GET_LESSON, {
    variables: { idCourse, idLesson, participant_id: userCtx.session_id },
    fetchPolicy: "network-only",
  })

  const [reflection, setReflection] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(null)

  useEffect(() => {
    if (data) {
      const start = Date.now()
      setStartTime(start)

      gaTrackingParams.event = "lesson_begin"

      gaTrackingParams.payload = {
        start_time: date,
        organization_id: data.participant.organization_id,
        cohort_id: data.participant.organization_cohort_id,
        course_id: idCourse,
        lesson_id: idLesson,
        lesson_name: data.lesson.title,
      }

      gATracking(gaTrackingParams)
    }
  }, [data])
  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const breadcrumb = [
    {
      label: data.course.title,
      command: () => navigate(`/view/course/${idCourse}`),
    },
    { label: data.lesson.title },
  ]
  const home = {
    icon: "pi pi-home",
    command: () => navigate("/"),
  }
  const items = [
    {
      key: data.course.id,
      label: data.course.title,
      icon: "pi pi-book",
      items: data.course.lessons.map((lesson) => ({
        key: lesson.id,
        label: lesson.title,
        command: () => navigate(`/view/course/${idCourse}/lesson/${lesson.id}`),
        className: lesson.id === idLesson && "underline",
      })),
    },
  ]

  const cloudFunctionsURL = services.cloudFunctionsURL()
  const postURL = `${cloudFunctionsURL}/formSubmit`

  const handleComplete = async () => {
    const {
      organization_cohort_id,
      organization_id,
      participant_coaching_registration_id,
      staff_id,
    } = data?.participant

    const postData = {
      template_id: "lesson_log",
      values: {
        lesson_log: {
          organization_cohort_id,
          organization_id,
          participant_coaching_registration_id,
          staff_id,
          course_id: idCourse,
          course_lesson_id: idLesson,
          course_lesson_name: data.lesson.title,
          course_lesson_reflection: reflection,
        },
      },
    }

    setSubmitting(true)

    try {
      const response = await axios.post(postURL, postData, {
        headers: {
          Authorization: "Bearer " + userCtx.token,
        },
      })
      console.log(response.data)
      await refetch()
    } catch (error) {
      console.error("There was an error completing the lesson!", error)
    } finally {
      setSubmitting(false)
    }
  }

  // Check if data.log is defined and has length
  const isLessonCompleted =
    data.log && data.log.length > 0
      ? data.log.some(
          (log) =>
            log.course_lesson_id === idLesson && log.course_id === idCourse
        )
      : false

  const handleCompleteButton = () => {
    const containerClass =
      "flex flex-column surface-200 mt-4 pt-3 pb-5 gap-3 border-top-1 border-bluegray-100"

    if (submitting) {
      return (
        <div className={containerClass}>
          <LoaderProgressBar />
        </div>
      )
    }

    if (isLessonCompleted) {
      return (
        <div className={containerClass}>
          {data.log.map(
            ({ course_lesson_id, course_lesson_reflection, course_id }) =>
              course_lesson_id === idLesson && course_id === idCourse ? (
                <div
                  key={course_lesson_id}
                  className="flex justify-content-center mt-2 px-6 font-italic text-600"
                >
                  "{course_lesson_reflection}"
                </div>
              ) : null
          )}
          <Button
            label="Completed"
            className="align-self-center text-lg w-24rem h-4rem"
            disabled
            id="CompletedBtn"
            onClick={(e) => {
              const buttonId = e.target.id

              const gaTrackingParams = {
                event: "button_click",
                payload: {
                  button_id: buttonId,
                  button_text: "Completed",
                  button_type: "Submit",
                  button_location: "participant_view_lesson",
                },
              }
              gATracking(gaTrackingParams)
            }}
          />
        </div>
      )
    }

    return (
      <div className={containerClass}>
        <InputTextarea
          value={reflection}
          onChange={(e) => setReflection(e.target.value)}
          rows={5}
          cols={34}
          autoResize
          placeholder="Write your reflection here..."
          className="align-self-center"
        />
        <Button
          label="Complete"
          severity="success"
          raised
          disabled={reflection === "" || !data?.participant?.staff_id} // Should come from Session
          className="align-self-center text-lg w-24rem h-4rem"
          onClick={() => {
            const endTime = Date.now()
            const elapsed = (endTime - startTime) / 1000
            setElapsedTime(elapsed)

            console.log(`Time elapsed: ${elapsed} seconds`)

            handleComplete()
            const {
              organization_cohort_id,
              organization_id,
              participant_coaching_registration_id,
              staff_id,
            } = data?.participant

            gaTrackingParams.event = "lesson_end"

            gaTrackingParams.payload = {
              organization_id: organization_id,
              cohort_id: organization_cohort_id,
              course_id: idCourse,
              course_lesson_id: idLesson,
              course_lesson_name: data.lesson.title,
              completation_time: elapsed,
            }

            gATracking(gaTrackingParams)
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <h1>{data.lesson.title}</h1>
      <BreadCrumb model={breadcrumb} home={home} />
      <p>{data.lesson.description}</p>

      <div className="flex align-items-start overflow-hidden gap-3 mt-5">
        <div className="flex-none flex w-3 align-items-center justify-content-center bg-bluegray-100 p-2">
          <PanelMenu
            model={items}
            expandedKeys={{
              [idCourse]: true,
            }}
            style={{ width: "100%" }}
          />
        </div>
        <div className="flex-grow-1 flex flex-column bg-bluegray-50">
          <RenderResourceList resourceList={data.lesson.resources} />
          {handleCompleteButton()}
        </div>
      </div>

      <br />
      <br />
    </div>
  )
}
