import React, { useContext } from "react"
import { useQuery, gql } from "@apollo/client"
import { Link } from "react-router-dom"
import { DateTime } from "luxon"
import { LoaderMedium } from "../../components/Loaders"
import { UserContext } from "../../context/userContext"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"

export default function SessionsLessons() {
  const userCtx = useContext(UserContext)
  const staffID = userCtx.session_id

  const LESSON_LOG = gql`
    query GetParticipantLessonLogByStaffId($staff_id: ID!) {
      participantLog: participantLessonLogByStaffId(staff_id: $staff_id) {
        participant_id
        participant_name_full
        course_name
        organization_cohort_name
        staff_id
        course_lesson_name
        course_lesson_reflection
        course_lesson_complete
        date_created
      }
    }
  `

  const { loading, error, data } = useQuery(LESSON_LOG, {
    variables: { staff_id: staffID },
    fetchPolicy: "network-only",
  })

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participant = data.participantLog

  const dateTemplate = (rowData) => {
    const date = DateTime.fromFormat(
      rowData.date_created,
      "yyyy-MM-dd HH:mm:ss"
    )
    const dateWithoutTime = date.startOf("day")
    return dateWithoutTime.toFormat("MM/dd/yyyy")
  }

  const nameTemplate = (rowData) => {
    return (
      <Link
        style={{
          color: "var(--branded-light-green)",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        to={`/participant/profile/${rowData.participant_id}`}
      >
        {rowData.participant_name_full}
      </Link>
    )
  }
  const redirect = () => {
    setTimeout(() => {
      window.open("https://calendly.com/login", "_blank")
    }, 0)
  }
  return (
    <div>
      <h1>Lesson Reflections</h1>
      <Button
        className="my-2"
        label="Go to Calendly"
        onClick={redirect}
      ></Button>

      <br />
      <br />
      <DataTable
        value={participant}
        tableStyle={{ minWidth: "50rem" }}
        paginator
        rows={10}
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="participant_name_full"
          header="Name"
          body={nameTemplate}
        />
        <Column
          sortable
          field="organization_cohort_name"
          header="Cohort"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="course_name"
          header="Course"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="course_lesson_name"
          header="Lesson"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="course_lesson_reflection"
          header="Lesson Reflection"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="date_created"
          header="Registration Date"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
          body={dateTemplate}
        />
      </DataTable>
    </div>
  )
}
