import React, { useState, useRef, useEffect } from "react"
import * as resourceList from "./Resources/list"

// Context
import { AddLessonCtx } from "../context/addLessonCtx"

// PR
import { Panel } from "primereact/panel"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { InputSwitch } from "primereact/inputswitch"
import { InputNumber } from "primereact/inputnumber"

import resourcesOpts from "../resourcesOpts"

function Resource(props) {
  switch (props.type) {
    case "audio":
      return <resourceList.TypeAudio />
    case "divider":
      return <resourceList.TypeDivider />
    case "editor":
      return <resourceList.TypeEditor />
    case "file":
      return <resourceList.TypeFile />
    case "text":
      return <resourceList.TypeText />
    case "title":
      return <resourceList.TypeTitle />
    case "video":
      return <resourceList.TypeVideo />
    case "shared":
      return <resourceList.TypeShared />
    default:
      return null
  }
}

const defaultResource = {
  content: {
    htmlTag: "",
    gs: "",
    title: "",
    description: "",
    value: "",
  },
  name: "",
  avgTime: 0,
  shared: false,
}

// Should use Hook instead. Please check: /src/pages/Resources/Edit.js

export default function AddResource(props) {
  const [type, setType] = useState(null)
  const [editResourceId, setEditResourceId] = useState(null)
  const [newResource, setNewResource] = useState(defaultResource)

  const editMode = props.editMode

  useEffect(() => {
    if (editMode) {
      setType(props.editResource.type)
      setNewResource(props.editResource)
      setEditResourceId(props.editResource.id)
    }
  }, [editMode])

  const ref = useRef(null)

  const handleReset = () => {
    setNewResource(defaultResource)
    setType(null)
  }

  const handleCancel = () => {
    handleReset()
    props.setEditMode(false)
  }

  const handleOnChange = (field, value) => {
    setNewResource({
      ...newResource,
      [field]: value,
    })
  }

  const handleSharedResourceOnChange = (id) => {
    setNewResource({
      ...newResource,
      id,
    })
  }

  const handleResourceContentOnChange = (field, value) => {
    setNewResource({
      ...newResource,
      content: {
        ...newResource.content,
        [field]: value,
      },
    })
  }

  const lessonCtxValue = {
    newResource,
    handleOnChange: handleResourceContentOnChange,
    handleSharedOnChange: handleSharedResourceOnChange,
  }

  const handleResourceMetadata = () => {
    return (
      <>
        <div className="flex flex-column">
          <label>Type</label>
          <Dropdown
            value={type}
            disabled={editMode}
            onChange={(e) => {
              setType(e.value)
              setNewResource({
                ...newResource,
                content: defaultResource.content,
              })
            }}
            options={resourcesOpts}
          />
        </div>

        <div className="flex flex-column">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={newResource.name}
            onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          />
        </div>

        <div className="flex flex-column">
          <label htmlFor="avgTime">Average Time (minutes)</label>
          <InputNumber
            id="avgTime"
            name="avgTime"
            value={
              newResource.avgTime ? (newResource.avgTime / 60000).toFixed(2) : 0
            }
            min={0}
            step={0.5}
            onChange={(e) => {
              const ms = e.value * 60000 || 0
              handleOnChange("avgTime", ms)
            }}
          />
        </div>

        <div className="flex flex-column">
          <label>Shared this Resource</label>
          <InputSwitch
            checked={newResource.shared}
            disabled={newResource.type === "shared"}
            onChange={(e) => {
              setNewResource({
                ...newResource,
                shared: e.value,
              })
            }}
          />
        </div>
      </>
    )
  }

  return (
    <AddLessonCtx.Provider value={lessonCtxValue}>
      <Panel
        ref={ref}
        header={editMode ? "Edit Resource" : "Add Resource"}
        toggleable
        className={`mb-5 ${editMode && "edit-mode"}`}
      >
        <div className="flex flex-column">
          <div className="flex flex-row align-content-start gap-5">
            <div className="flex flex-column w-4 gap-2">
              {handleResourceMetadata()}
              <Button
                label={editMode ? "Update" : "Add Resource"}
                onClick={() => {
                  if (editMode) {
                    props.handleEditResource(newResource, editResourceId)
                    return handleReset()
                  }
                  newResource.type = type
                  props.handleAddResource(newResource)
                  handleReset()
                }}
              />

              {editMode ? (
                <Button
                  label="Cancel"
                  className="p-button-secondary"
                  onClick={handleCancel}
                  outlined
                  size="small"
                />
              ) : (
                <Button
                  label="Reset"
                  disabled={editMode}
                  className="p-button-secondary"
                  onClick={handleReset}
                  outlined
                  size="small"
                />
              )}
            </div>
            {type && (
              <div className="flex flex-column w-8 p-4 surface-100 border-round">
                <Resource type={type} />
              </div>
            )}
          </div>
        </div>
      </Panel>
    </AddLessonCtx.Provider>
  )
}
