import React, { useRef, useEffect, useState, useContext } from "react"
import { Messages } from "primereact/messages"
import { Button } from "primereact/button"
import { useNavigate, useLocation } from "react-router-dom"

import { UserContext } from "../../context/userContext"

function ErrorPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const messages = useRef(null)

  const userCtx = useContext(UserContext)

  const [isMessageShown, setIsMessageShown] = useState(false)

  // Extract the error message from the location state
  const errorMessage =
    location.state?.errorMessage || "Something went wrong unexpectedly."

  useEffect(() => {
    if (messages.current && !isMessageShown) {
      messages.current.clear() // Clear any previous messages
      messages.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        sticky: true,
        closable: false,
      })
      setIsMessageShown(true)
    }
  }, [errorMessage, isMessageShown])

  const handleButtonClick = () => {
    navigate("/")
  }

  return (
    <div className="error-page">
      <h1>Oops! Something went wrong.</h1>
      <Messages ref={messages} />
      <p>
        We encountered an issue processing your request. Please contact our
        support team for assistance at{" "}
        <a href="mailto:support@thirdhorizonstrategies.com">
          support@thirdhorizonstrategies.com
        </a>
        . We apologize for the inconvenience.
      </p>

      {userCtx?.session_id && (
        <Button
          label="Go to Home"
          icon="pi pi-home"
          onClick={handleButtonClick}
        />
      )}
    </div>
  )
}

export default ErrorPage
