import React, { useContext } from "react"
import { gql, useQuery } from "@apollo/client"

import { Field } from "formik"
import { LoaderMedium } from "../../../../components/Loaders"

// PR
import { Dropdown } from "primereact/dropdown"

// Ctx
import { FormContext } from "../../context/FormContext"

const ORGANIZATION_LIST = gql`
  query GetCourseList {
    courses {
      id
      title
    }
  }
`

export default function TypeSelectedCourseId({ field }) {
  const form = useContext(FormContext)
  const blockCurrent = form.blockCurrent

  const isDisabled = form
    ? form.blocksSaved[blockCurrent.block_name] || field.field_disabled_on_edit
    : false
  const isRequired = field.field_validation?.required?.[0] === "true"

  const { loading, error, data } = useQuery(ORGANIZATION_LIST)

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const organizationList = data.courses.map((org) => {
    return {
      label: org.title,
      value: org.id,
    }
  })

  return (
    <Field name={field.field_name}>
      {({ field: formikField, meta, form: formik }) => {
        return (
          <div className="flex flex-column gap-1 mb-5">
            <label htmlFor={formikField.name}>
              {field.field_label}
              {isRequired && "*"}
            </label>
            <Dropdown
              disabled={isDisabled}
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onChange={formikField.onChange}
              onBlur={() => formik.setFieldTouched(formikField.name)}
              options={organizationList}
            />
            {field.field_description && (
              <small>{field.field_description}</small>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm text-red-600 font-semibold">
                {meta.error}
              </div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
