import React, { useContext, useRef, useEffect } from "react"

import { useQuery, gql } from "@apollo/client"
import { DateTime } from "luxon"
import { useLocation, Link } from "react-router-dom"

import { LoaderMedium } from "../../../components/Loaders"
import { UserContext } from "../../../context/userContext"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

//Ga
import gATracking from "../../../helpers/gaTracking"

export default function StaffHomeUser() {
  const userCtx = useContext(UserContext)
  const staffID = userCtx.session_id
  const hasLoggedRef = useRef(false)

  const location = useLocation()

  const PARTICIPANT_LIST = gql`
    query ParticipantsByStaff($staff_id: ID!) {
      participantsByStaff: participantsByStaff(staff_id: $staff_id) {
        participant_id
        participant_name_full
        participant_email
        organization_cohort_name
        organization_name
        participant_sms_phone
        date_created
      }
    }
  `

  const { loading, error, data } = useQuery(PARTICIPANT_LIST, {
    variables: { staff_id: staffID },
    fetchPolicy: "network-only",
  })
  const gaTrackingParams = {
    event: "",
    userCtx,
    payload: {},
  }

  useEffect(() => {
    if (location.state?.fromLogin && !hasLoggedRef.current) {
      gaTrackingParams.event = "login_successfull"

      gATracking(gaTrackingParams)

      hasLoggedRef.current = true
    }
  }, [location.state])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participant = data.participantsByStaff

  const dateTemplate = (rowData) => {
    const date = DateTime.fromFormat(
      rowData.date_created,
      "yyyy-MM-dd HH:mm:ss"
    )
    const dateWithoutTime = date.startOf("day")
    return dateWithoutTime.toFormat("MM/dd/yyyy")
  }
  const nameTemplate = (rowData) => {
    return (
      <Link
        style={{
          color: "var(--branded-light-green)",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={() => {
          gaTrackingParams.event = "button_click"

          gaTrackingParams.payload = {
            button_name: rowData.participant_name_full,
            button_text: rowData.participant_name_full,
            buuton_type: "participant_link",
            button_location: "StaffUserHome",
          }

          gATracking(gaTrackingParams)
        }}
        to={`/participant/profile/${rowData.participant_id}`}
      >
        {rowData.participant_name_full}
      </Link>
    )
  }

  return (
    <div>
      <h1>Roster</h1>
      <DataTable
        value={participant}
        paginator
        rows={10}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full	"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          field="participant_name_full"
          header="Name"
          body={nameTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_email"
          header="Email"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_sms_phone"
          header="Phone Number"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="organization_name"
          header="Organization"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="organization_cohort_name"
          header="Cohort"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="date_created"
          header="Registration Date"
          body={dateTemplate}
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>
    </div>
  )
}
